var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "home-btn d-none d-sm-block" },
        [
          _c("router-link", { staticClass: "text-dark", attrs: { to: "/" } }, [
            _c("i", { staticClass: "mdi mdi-home-variant h2" })
          ])
        ],
        1
      ),
      _c("div", { staticClass: "account-pages my-5 pt-sm-5" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "mb-5 d-block auth-logo",
                      attrs: { to: "/" }
                    },
                    [
                      _c("img", {
                        staticClass: "logo logo-dark",
                        attrs: {
                          src: require("@/assets/images/logo-dark.png"),
                          alt: "",
                          width: "180"
                        }
                      }),
                      _c("img", {
                        staticClass: "logo logo-light",
                        attrs: {
                          src: require("@/assets/images/logo-light.png"),
                          alt: "",
                          width: "180"
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "row align-items-center justify-content-center" },
            [
              _c("div", { staticClass: "col-md-8 col-lg-6 col-xl-5" }, [
                _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body p-4" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      { staticClass: "p-2 mt-4" },
                      [
                        _c(
                          "b-alert",
                          {
                            staticClass: "mt-3",
                            attrs: { variant: "danger", dismissible: "" },
                            model: {
                              value: _vm.isAuthError,
                              callback: function($$v) {
                                _vm.isAuthError = $$v
                              },
                              expression: "isAuthError"
                            }
                          },
                          [_vm._v(_vm._s(_vm.authError))]
                        ),
                        _vm.notification.message
                          ? _c(
                              "div",
                              { class: "alert alert-" + _vm.notification.type },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.notification.message) + " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "b-form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.tryToLogIn($event)
                              }
                            }
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-3",
                                attrs: {
                                  id: "input-group-1",
                                  label: "Email",
                                  "label-for": "input-1"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  class: {
                                    "is-invalid":
                                      _vm.submitted && _vm.$v.email.$error
                                  },
                                  attrs: {
                                    id: "input-1",
                                    type: "text",
                                    placeholder: "Digite o e-mail"
                                  },
                                  model: {
                                    value: _vm.email,
                                    callback: function($$v) {
                                      _vm.email = $$v
                                    },
                                    expression: "email"
                                  }
                                }),
                                _vm.submitted && _vm.$v.email.$error
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [
                                        !_vm.$v.email.required
                                          ? _c("span", [
                                              _vm._v("O e-mail é obrigatório.")
                                            ])
                                          : _vm._e(),
                                        !_vm.$v.email.email
                                          ? _c("span", [
                                              _vm._v(
                                                "Por favor insira um email válido."
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-3",
                                attrs: { id: "input-group-2" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "float-end" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "text-muted",
                                        attrs: {
                                          to: { name: "restaurar-senha" }
                                        }
                                      },
                                      [_vm._v("Esqueceu a senha?")]
                                    )
                                  ],
                                  1
                                ),
                                _c("label", { attrs: { for: "input-2" } }, [
                                  _vm._v("Senha")
                                ]),
                                _c("b-form-input", {
                                  class: {
                                    "is-invalid":
                                      _vm.submitted && _vm.$v.password.$error
                                  },
                                  attrs: {
                                    id: "input-2",
                                    type: "password",
                                    placeholder: "Digite a senha"
                                  },
                                  model: {
                                    value: _vm.password,
                                    callback: function($$v) {
                                      _vm.password = $$v
                                    },
                                    expression: "password"
                                  }
                                }),
                                _vm.submitted && !_vm.$v.password.required
                                  ? _c(
                                      "div",
                                      { staticClass: "invalid-feedback" },
                                      [_vm._v(" O Senha é obrigatório. ")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("div", { staticClass: "form-check" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.concordoPolitica,
                                    expression: "concordoPolitica"
                                  }
                                ],
                                staticClass: "form-check-input",
                                class: {
                                  "is-invalid":
                                    _vm.submitted &&
                                    _vm.$v.concordoPolitica.$invalid
                                },
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.concordoPolitica)
                                    ? _vm._i(_vm.concordoPolitica, null) > -1
                                    : _vm.concordoPolitica
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.concordoPolitica,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.concordoPolitica = $$a.concat([
                                            $$v
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.concordoPolitica = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.concordoPolitica = $$c
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "auth-remember-check" }
                                },
                                [
                                  _vm._v("Li e concordo com a "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openPolitica()
                                        }
                                      }
                                    },
                                    [_vm._v("Política de Privacidade")]
                                  )
                                ]
                              ),
                              _vm.submitted && _vm.$v.concordoPolitica.$invalid
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        " Você deve concordar com a Política de Privacidade. "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "form-check" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.concordoTermos,
                                    expression: "concordoTermos"
                                  }
                                ],
                                staticClass: "form-check-input",
                                class: {
                                  "is-invalid":
                                    _vm.submitted &&
                                    _vm.$v.concordoTermos.$invalid
                                },
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.concordoTermos)
                                    ? _vm._i(_vm.concordoTermos, null) > -1
                                    : _vm.concordoTermos
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.concordoTermos,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.concordoTermos = $$a.concat([
                                            $$v
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.concordoTermos = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.concordoTermos = $$c
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "auth-remember-check" }
                                },
                                [
                                  _vm._v("Li e concordo com os "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.openTermos()
                                        }
                                      }
                                    },
                                    [_vm._v("Termos de Uso")]
                                  )
                                ]
                              ),
                              _vm.submitted && _vm.$v.concordoTermos.$invalid
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        " Você deve concordar com as Termos de Uso. "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]),
                            _c(
                              "div",
                              { staticClass: "mt-3 text-end" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-sm",
                                    attrs: {
                                      type: "submit",
                                      variant: "primary"
                                    }
                                  },
                                  [_vm._v("Login")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "mt-5 text-center" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.appConfig.devYear) +
                        " ® " +
                        _vm._s(_vm.appConfig.title) +
                        " " +
                        _vm._s(
                          _vm.devConfig
                            ? " - Versão " + _vm.devConfig.versao
                            : ""
                        )
                    )
                  ]),
                  _vm.devConfig
                    ? _c("p", [
                        _vm._v("Desenvolvido por "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.devConfig.devUrl,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(_vm.devConfig.devNome))]
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]
          )
        ])
      ]),
      _c(
        "b-modal",
        {
          attrs: { size: "xl", "hide-footer": "", title: _vm.titleLgpd },
          model: {
            value: _vm.modalLgpdState,
            callback: function($$v) {
              _vm.modalLgpdState = $$v
            },
            expression: "modalLgpdState"
          }
        },
        [
          _c("div", { staticClass: "p-2" }, [
            _c("div", {
              staticStyle: { "background-color": "#fff !important" },
              attrs: { id: "contentLgpd" },
              domProps: { innerHTML: _vm._s(_vm.contentLgpd) }
            }),
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "info" },
                    on: {
                      click: function($event) {
                        return _vm.print()
                      }
                    }
                  },
                  [_vm._v("Imprimir")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center mt-2" }, [
      _c("h5", { staticClass: "text-primary" }, [
        _vm._v("Bem-vindo de Volta!")
      ]),
      _c("p", { staticClass: "text-muted" }, [
        _vm._v(" Faça login para continuar em SaudeCare ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }