<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo-dark.png"
                  alt
                  width="180"
                  class="logo logo-dark"
                />
                <img
                  src="@/assets/images/logo-light.png"
                  alt
                  width="180"
                  class="logo logo-light"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Bem-vindo de Volta!</h5>
                  <p class="text-muted">
                    Faça login para continuar em SaudeCare
                  </p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div
                    v-if="notification.message"
                    :class="'alert alert-' + notification.type"
                  >
                    {{ notification.message }}
                  </div>

                  <b-form @submit.prevent="tryToLogIn">
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Email"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="text"
                        placeholder="Digite o e-mail"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                      ></b-form-input>
                      <div
                        v-if="submitted && $v.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.email.required"
                          >O e-mail é obrigatório.</span
                        >
                        <span v-if="!$v.email.email"
                          >Por favor insira um email válido.</span
                        >
                      </div>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <div class="float-end">
                        <router-link
                          :to="{ name: 'restaurar-senha' }"
                          class="text-muted"
                          >Esqueceu a senha?</router-link
                        >
                      </div>
                      <label for="input-2">Senha</label>
                      <b-form-input
                        id="input-2"
                        v-model="password"
                        type="password"
                        placeholder="Digite a senha"
                        :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && !$v.password.required"
                        class="invalid-feedback"
                      >
                        O Senha é obrigatório.
                      </div>
                    </b-form-group>

                    <div class="form-check">
                      <input
                        v-model="concordoPolitica"
                        type="checkbox"
                        class="form-check-input"
                        :class="{
                          'is-invalid': submitted && $v.concordoPolitica.$invalid,
                        }"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >Li e concordo com a <a href="javascript:void(0)" @click="openPolitica()">Política de Privacidade</a></label
                      >
                      <div
                        v-if="submitted && $v.concordoPolitica.$invalid"
                        class="invalid-feedback"
                      >
                        Você deve concordar com a Política de Privacidade.
                      </div>
                    </div>
                    <div class="form-check">
                      <input
                        v-model="concordoTermos"
                        type="checkbox"
                        class="form-check-input"
                        :class="{
                          'is-invalid': submitted && $v.concordoTermos.$invalid,
                        }"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >Li e concordo com os <a href="javascript:void(0)" @click="openTermos()">Termos de Uso</a></label
                      >
                      <div
                        v-if="submitted && $v.concordoTermos.$invalid"
                        class="invalid-feedback"
                      >
                        Você deve concordar com as Termos de Uso.
                      </div>
                    </div>

                    <div class="mt-3 text-end">
                      <b-button type="submit" variant="primary" class="w-sm"
                        >Login</b-button
                      >
                    </div>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>{{ appConfig.devYear }} ® {{ appConfig.title }} {{ devConfig ? ` - Versão ${devConfig.versao}` : "" }}</p>
              <p v-if="devConfig">Desenvolvido por <a :href="devConfig.devUrl" target="_blank">{{ devConfig.devNome }}</a></p>
            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>

    <b-modal v-model="modalLgpdState" size="xl" hide-footer :title="titleLgpd">
      <div class="p-2">
        <div v-html="contentLgpd" id="contentLgpd" style="background-color: #fff !important;"></div>
        <div class="mt-2">
          <b-button variant="info" @click="print()">Imprimir</b-button>
        </div>
      </div>
      
    </b-modal>

  </div>
</template>

<style lang="css">
@media print {
  body {
    background-color: #fff !important;
  }
}

</style>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import { getAll as getConfig, getPoliticaPrivacidade, getTermosUso } from "@/services/configuracao.service"

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      appConfig,
      devConfig: null,
      email: process.env.VUE_APP_LOGIN_USER,
      password: process.env.VUE_APP_LOGIN_PASS,
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,

      concordoPolitica: false,
      concordoTermos: false,

      modalLgpdState: false,
      titleLgpd: '',
      contentLgpd: ''
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
    concordoPolitica: {
      sameAs: sameAs( () => true ) 
    },
    concordoTermos: {
      sameAs: sameAs( () => true ) 
    }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");

    getConfig().then(response => {
      this.devConfig = response;
    });
  },
  methods: {
    openPolitica() {
      this.titleLgpd = 'Saúde Care - Política de Privacidade'
      getPoliticaPrivacidade().then(({data}) => {
        this.contentLgpd = data
        this.modalLgpdState = true;
      });
    },
    openTermos() {
      this.titleLgpd = 'Saúde Care - Termos de Uso'
      getTermosUso().then(({data}) => {
        this.contentLgpd = data
        this.modalLgpdState = true;
      });
    },

    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.tryingToLogIn = true;
        // Reset the authError if it existed.
        this.authError = null;
        const { email, password } = this;
        this.$store.dispatch("authfack/login", {
          email,
          password,
          lgpdId: this.devConfig.lgpdId
        });
      }
    },

    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('contentLgpd').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <title>${this.titleLgpd}</title>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    }
  },
};
</script>
